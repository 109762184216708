import React from "react"
import Button from "../../Elements/Button"

export const authFormFields = (values) => {
  return [
    {
      type: "text",
      isNumeric: true,
      hasAddons: true,
      addonLeft: <Button className="is-static">🇵🇭 +63</Button>,
      name: "mobileNumber",
      label: "Mobile Number",
      isRequired: true,
      value: values?.mobileNumber,
      isFollowUpQuestion: false,
      placeholder: "9123456789",
      inclusions: ["sign-in"],
      inputmode: "numeric",
      maxLength: 10,
    },
    {
      type: "text",
      isNumeric: true,
      name: "otp",
      label: "One-time PIN (OTP)",
      helper: `Enter the OTP sent to +63${values?.mobileNumber}. The OTP is only valid for one minute.`,
      isRequired: true,
      value: values?.otp,
      isFollowUpQuestion: false,
      placeholder: "••••••",
      inclusions: ["verify-otp"],
      inputmode: "numeric",
      maxLength: 6,
    },
    {
      type: "date",
      // isNumeric: true,
      name: "birthday",
      label: "Birthday",
      // helper: `Enter the OTP sent to +63${values?.mobileNumber}. The OTP is only valid for 1 minute.`,
      isRequired: true,
      values: values?.birthday,
      isFollowUpQuestion: false,
      // placeholder: "••••••",
      inclusions: ["verify-birthday"],
      // inputmode: "numeric",
      // maxLength: 6,
      dateConfig: `{"birthday": true}`,
      helper:
        "If you forgot your birthday, email us at pulsecare@medgrocer.com with the subject “Forgotten Birthday” and attach a valid ID.",
    },
    {
      type: "select",
      name: "idType",
      label: "ID Type",
      isRequired: true,
      value: values?.idType,
      isFollowUpQuestion: false,
      inclusions: ["verify-birthday"],
    },
    {
      type: "text",
      name: "lastName",
      label: "Patient Last Name",
      isRequired: true,
      placeholder: "Dela Cruz",
      isFollowUpQuestion: false,
      inclusions: ["verify-name"],
    },
    {
      type: "upload",
      name: "documents",
      label: "Upload Valid ID",
      isRequired: true,
      value: values?.documents,
      isFollowUpQuestion: false,
      inclusions: ["verify-birthday"],
      fieldNames: ["documents"],
      message: {
        content: (
          <div>
            Valid IDs include:
            <ul>
              <li>SC/PWD ID</li>
              <li>UMID</li>
              <li>GSIS ID</li>
              <li>SSS ID</li>
              <li>Driver’s license</li>
              <li>Passport</li>
              <li>Other government-issued IDs with full name and birthday</li>
            </ul>
          </div>
        ),
        color: "light",
      },
      guidelines: [
        "Lay your ID on a flat surface. Please take pictures in landscape mode.",
        "Take the picture with good lighting. Do not block the light source.",
        "Make sure the entire ID is captured.",
        "Move your phone closer and capture the ID in segments if needed.",
        "Tap to focus and make the document clearer.",
        "Connect to WiFi for faster uploading.",
        "You can only upload a maximum of two pictures, with a maximum file size of 5MB each. Set your camera's quality settings from High to Standard to ensure optimized file size.",
      ],
    },
    {
      type: "text",
      isNumeric: true,
      hasAddons: true,
      addonLeft: <Button className="is-static">🇵🇭 +63</Button>,
      name: "oldMobileNumber",
      label: "Old Mobile Number",
      isRequired: true,
      value: values?.oldMobileNumber,
      isFollowUpQuestion: false,
      placeholder: "9123456789",
      inclusions: ["update-number"],
      inputmode: "numeric",
      maxLength: 10,
    },
    {
      type: "text",
      isNumeric: true,
      hasAddons: true,
      addonLeft: <Button className="is-static">🇵🇭 +63</Button>,
      name: "newMobileNumber",
      label: "New Mobile Number",
      isRequired: true,
      value: values?.newMobileNumber,
      isFollowUpQuestion: false,
      placeholder: "9123456789",
      inclusions: ["update-number"],
      inputmode: "numeric",
      maxLength: 10,
    },
    {
      type: "select",
      name: "idType",
      label: "ID Type",
      isRequired: true,
      isFollowUpQuestion: false,
      inclusions: ["update-number"],
      placeholder: "Select Id",
    },
    {
      type: "upload",
      name: "documents",
      label: "Upload Valid ID",
      isRequired: true,
      value: values?.documents,
      isFollowUpQuestion: false,
      inclusions: ["update-number"],
      fieldNames: ["documents"],
      message: {
        content: (
          <div>
            Valid IDs include:
            <ul>
              <li>SC/PWD ID</li>
              <li>UMID</li>
              <li>GSIS ID</li>
              <li>SSS ID</li>
              <li>Driver’s license</li>
              <li>Passport</li>
              <li>Other government-issued IDs with full name and birthday</li>
            </ul>
          </div>
        ),
        color: "light",
      },
      guidelines: [
        "Lay your ID on a flat surface. Please take pictures in landscape mode.",
        "Take the picture with good lighting. Do not block the light source.",
        "Make sure the entire ID is captured.",
        "Move your phone closer and capture the ID in segments if needed.",
        "Tap to focus and make the document clearer.",
        "Connect to WiFi for faster uploading.",
        "You can only upload a maximum of two pictures, with a maximum file size of 5MB each. Set your camera's quality settings from High to Standard to ensure optimized file size.",
      ],
    },
  ]
}
